var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('b-container',{staticClass:"py-5"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"px-1 px-lg-5",attrs:{"lg":"5"}},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/svg/Contact/asset-01.svg')}})],1),_c('b-col',{attrs:{"lg":"5"}},[_c('h1',{staticClass:"mb-3 font-weight-bolder text-dark text-lg-left text-center my-3 my-lg-1"},[_vm._v(" Contact ")]),_c('validation-observer',{ref:"refContactUs"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"First Name"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Last Name"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Email Address"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Phone Number"},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Message"},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-button',{attrs:{"block":"","type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }