



























































































































import { Component, Vue } from 'vue-property-decorator'
import { BContainer, BRow, BCol, BImg, BButton, VBToggle, BCard, BCardText, BFormInput, BCardBody, BModal, VBModal, BBadge, BForm, BFormGroup, BFormTextarea } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import ModalDownload from '@/views/pages/components/modalDownload.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

@Component({
  name: 'ContactPage',
  components: {
    BContainer, BRow, BCol, BImg, BButton, AppCollapse, AppCollapseItem, BCard, BCardText, BCardBody, BFormInput, BModal, vSelect, ModalDownload, BBadge, BForm, BFormGroup, BFormTextarea, ValidationProvider, ValidationObserver, ToastificationContent
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal
  }
})
export default class ContactPage extends Vue {
  $refs!: { refContactUs }
  private mounted () {
    const rules = [email, required]
    Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
  }
  private destroyed () {
    this.$destroy()
  }
  private form : any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  }

  private validationForm () {
    this.$refs.refContactUs.validate().then(success => {
      if (success) {
        this.handleSubmitForm()
      }
    })
  }
  private async handleSubmitForm () {
    await this.$recaptchaLoaded()
    const token = await this.$recaptcha('contact')
    if (token) {
      this.form['g-recaptcha-response'] = token
      // action goes here
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'Data has been saved.',
          variant: 'primary'
        }
      })
      // end of action
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'recaptcha error',
          variant: 'danger'
        }
      })
    }
  }
}
